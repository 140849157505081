import React from "react"
import * as styles from "./AuthDetailsEditForm.module.scss"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyRadioInputField } from "@parachutehealth/canopy-radio-input-field"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { useComboboxSearch } from "hooks/useComboboxSearch/useComboboxSearch"
import { Formik, FormikValues } from "formik"
import { capitalize } from "utilities/string"
import classNames from "classnames"
import {
  CarrierAuthorization,
  CarrierAuthorizationReferralTypesEnum,
  RankedDiagnosis,
} from "../../../../../graphql/__generated__/graphql"
import { Icd10Code } from "sharedTypes"
import { searchIcd10Codes } from "../../api"
import { sendError } from "utilities/error"
import { AuthDiagnosisEditDescription } from "./AuthDiagnosisEditDescription"

export interface AuthDetailsEditFormProps {
  onCancel: () => void
  onSubmit: (values) => void
  isAuthSubmitted: boolean
  authRequest: CarrierAuthorization
}

type DiagnosisOptionItem = {
  description: string
} & OptionItem

const REFERRAL_TYPE_OPTIONS = [
  {
    label: capitalize(CarrierAuthorizationReferralTypesEnum.Initial),
    value: CarrierAuthorizationReferralTypesEnum.Initial,
  },
  {
    label: capitalize(CarrierAuthorizationReferralTypesEnum.Reauthorization),
    value: CarrierAuthorizationReferralTypesEnum.Reauthorization,
  },
]

export const AuthDetailsEditForm: React.FC<AuthDetailsEditFormProps> = ({
  onCancel,
  onSubmit,
  isAuthSubmitted,
  authRequest,
}) => {
  const fetchIcd10Codes = async (query: string = ""): Promise<Icd10Code[]> => {
    try {
      return await searchIcd10Codes(query)
    } catch (e) {
      sendError(`Failed to fetch icd10 codes with query: ${query}. Error: ${e}`)
      return []
    }
  }

  const diagnosesSearchParams = {
    fetchMethod: fetchIcd10Codes,
    optionGetter: (item: Icd10Code): DiagnosisOptionItem => ({
      value: item.codeLabel || "",
      label: item.codeLabel || "",
      description: item.description || "",
    }),
  }

  const {
    options: primaryDiagnosisOptions,
    loading: primaryDiagnosisLoading,
    handleSearch: handlePrimaryDiagnosisSearch,
  } = useComboboxSearch<Icd10Code>(diagnosesSearchParams)

  const {
    options: otherDiagnosesOptions,
    loading: otherDiagnosesLoading,
    handleSearch: handleOtherDiagnosisSearch,
  } = useComboboxSearch<Icd10Code>(diagnosesSearchParams)

  const primaryRankedDiagnosis: RankedDiagnosis =
    authRequest?.rankedDiagnoses?.find((dx) => dx.rank === "primary") || {}

  const otherRankedDiagnoses: RankedDiagnosis[] =
    authRequest?.rankedDiagnoses?.filter((dx) => dx.rank !== "primary") || []

  const rankedDiagnosisToOption = (
    diagnosis: RankedDiagnosis
  ): DiagnosisOptionItem => ({
    value: diagnosis?.icd10Code?.code || "",
    label: diagnosis?.icd10Code?.code || "",
    description: diagnosis?.icd10Code?.description || "",
  })

  const initialValues: FormikValues = {
    referralType: authRequest?.referralType,
    primaryDiagnosis: rankedDiagnosisToOption(primaryRankedDiagnosis),
    otherDiagnoses: otherRankedDiagnoses.map((dx) =>
      rankedDiagnosisToOption(dx)
    ),
  }

  const validate = (values: FormikValues) => {
    const errors = {}
    if (!values.referralType) {
      errors["referralType"] = "Selection required"
    }

    return errors
  }

  const handleOnSubmit = async (values: FormikValues) => {
    onSubmit({
      carrierAuthorizationId: authRequest.id,
      referralType: values.referralType,
    })
  }

  const diagnosisOptionRenderFunction = ({ option }) => (
    <div>
      <span className="bold">{option.label}</span>
      <br />
      <span>{option.description}</span>
    </div>
  )

  const diagnosisOptionSelectedFormatFunction = (option) => {
    return !!option?.label && !!option?.description
      ? `${option.label} - ${option.description}`
      : option?.label ?? ""
  }

  const isReauthReferral: boolean =
    authRequest?.referralType ===
    CarrierAuthorizationReferralTypesEnum.Reauthorization

  return (
    <>
      <Formik
        onSubmit={handleOnSubmit}
        initialValues={initialValues}
        validate={validate}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched,
        }) => {
          return (
            <CanopyForm onSubmit={handleSubmit}>
              <CanopyRadioInputField
                role="radiogroup"
                label="Referral type"
                name="referralType"
                options={REFERRAL_TYPE_OPTIONS}
                value={values.referralType || undefined}
                onChange={handleChange}
                className={styles.radioInput}
                required
                feedbackMessage={
                  touched.referralType
                    ? (errors.referralType as string)
                    : undefined
                }
              />
              <hr className={styles.pageBreak} />
              <AuthDiagnosisEditDescription
                isReauthReferral={isReauthReferral}
                isAuthSubmitted={isAuthSubmitted}
              />
              <div
                className={classNames(
                  "canopy-m-0 canopy-mt-8x canopy-p-8x",
                  styles.background
                )}
              >
                <CanopyComboboxField
                  id="primaryDiagnosis"
                  className="canopy-mb-12x"
                  label="Primary diagnosis code"
                  required
                  description="Search and select by ICD-10 or description. There can only be one primary diagnosis."
                  disabled={isReauthReferral && !!values.primaryDiagnosis}
                  value={values.primaryDiagnosis}
                  onChange={(value) => setFieldValue("primaryDiagnosis", value)}
                  onInputChange={(value) => handlePrimaryDiagnosisSearch(value)}
                  options={primaryDiagnosisOptions}
                  optionRenderFunction={diagnosisOptionRenderFunction}
                  optionSelectedFormatFunction={
                    diagnosisOptionSelectedFormatFunction
                  }
                  loading={primaryDiagnosisLoading}
                />
                <CanopyComboboxField
                  id="otherDiagnoses"
                  label="Other diagnosis codes"
                  description="Search and select by ICD-10 or description. You may select more than one."
                  multiple
                  value={values.otherDiagnoses}
                  onChange={(value) => setFieldValue("otherDiagnoses", value)}
                  onInputChange={(value) => handleOtherDiagnosisSearch(value)}
                  options={otherDiagnosesOptions}
                  optionRenderFunction={diagnosisOptionRenderFunction}
                  optionSelectedFormatFunction={
                    diagnosisOptionSelectedFormatFunction
                  }
                  loading={otherDiagnosesLoading}
                />
              </div>
              <hr className={styles.pageBreak} />
              <div className={styles.formButtonSection}>
                <CanopyButton variant="primary" type="submit">
                  Save
                </CanopyButton>
                <CanopyButton variant="tertiary" onClick={() => onCancel()}>
                  Cancel
                </CanopyButton>
              </div>
            </CanopyForm>
          )
        }}
      </Formik>
    </>
  )
}
