import React, { useState } from "react"
import classNames from "classnames"
import { CanopyIcon } from "@parachutehealth/canopy-icon"

import { toggleSavedFacility } from "../api"
import * as styles from "./FavoriteIconButton.module.scss"
import { EventCategory, trackEvent } from "utilities/tracking"

const getFavoriteIconClassName = (saved?: boolean) => {
  const iconStyle = saved
    ? styles.favoriteIconSaved
    : styles.favoriteIconUnsaved

  return classNames([styles.iconButton, iconStyle])
}

const getTitle = (name: string, saved?: boolean) => {
  if (saved) {
    return `un-favorite ${name}`
  }
  return `favorite ${name}`
}

type Props = {
  clinicalFacilityId: string
  supplierOrgExternalId: string
  favorite?: boolean
  name: string
  onError: (e: Error | unknown) => void
}

const FavoriteIconButton = ({
  clinicalFacilityId,
  supplierOrgExternalId,
  favorite,
  name,
  onError,
}: Props) => {
  const [isSaved, setIsSaved] = useState(favorite)

  const toggleSaved = async () => {
    const newSaved = !isSaved
    try {
      await toggleSavedFacility({
        favorite: newSaved,
        clinicalFacilityId,
        supplierOrganizationId: supplierOrgExternalId,
      })
      if (newSaved) {
        trackEvent(
          EventCategory.SalesCollaborationTools,
          `facility_saved_on_network_${clinicalFacilityId}`
        )
      } else {
        trackEvent(
          EventCategory.SalesCollaborationTools,
          `facility_unsaved_on_network_${clinicalFacilityId}`
        )
      }
      setIsSaved(newSaved)
    } catch (e) {
      onError(e)
    }
  }

  const a11yTitle = getTitle(name, isSaved)

  return (
    <CanopyIcon
      role="button"
      aria-pressed={isSaved}
      className={getFavoriteIconClassName(isSaved)}
      onClick={toggleSaved}
      name={isSaved ? "star" : "star-outline"}
      size="medium"
      title={a11yTitle}
    />
  )
}

export { FavoriteIconButton }
